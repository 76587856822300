import type { PropsWithChildren } from 'react'
import { mixins } from '../../../styles'

export interface ITitle extends PropsWithChildren {
  addHashtag?: boolean
  align?: string
  color?: string
  dataTestId?: string
}

export function Title({ addHashtag, align, children, color, dataTestId }: ITitle): JSX.Element {
  return (
    <>
      <h1 className={`Title ${addHashtag ? 'Title_Hashtag' : ''}`} data-testid={dataTestId}>
        {children}
      </h1>

      <style jsx>{`
        .Title {
          ${color ? `color: ${color};` : ''};
          ${align ? `text-align: ${align};` : ''};
        }
      `}</style>

      <style jsx>{`
        .Title.Title_Hashtag,
        .Title.Title_Hashtag > * {
          display: inline;
        }

        .Title_Hashtag::before {
          content: '#';
          display: inline-block;
        }

        .Title,
        .Title h1 {
          font-weight: 800;
          font-size: 32px;
          margin: 0;
        }

        @media ${mixins.mediaQuery.tabletPaysage} {
          .Title,
          .Title h1 {
            font-size: 48px;
          }
        }
      `}</style>
    </>
  )
}
