import { useAmp } from 'next/amp'
import { Icon } from '../../atoms/Icon'
import { characterCount } from '../../../helpers/string'
import { mixins, theme } from '../../../styles'

export type IExpandButton = {
  height?: string
  color?: string
  backgroundColor?: string
  text: string
  maxChar?: number
  disabledDesktop?: boolean
}

export function ExpandableZone({
  height,
  text,
  color = theme.cssVars.white,
  backgroundColor = theme.cssVars.deepBlue,
  maxChar = 400,
  disabledDesktop = false,
}: IExpandButton): JSX.Element {
  const isAmp = useAmp()
  const nbChar = characterCount(text)

  return (
    <>
      <div className={`ExpandableZone${isAmp ? ' ExpandableZone__isAmp' : ''}`}>
        <input type="checkbox" id="ExpandedInput" defaultChecked={false} />
        <div dangerouslySetInnerHTML={{ __html: text }} className="ExpandableZone__Content" />
        {nbChar > maxChar && (
          <div className="ExpandableZone__Expands">
            <div className="ExpandableZone__Blur" />
            <div className="ExpandableZone__Button flex">
              <label
                className="ExpandableZone__Button__Label flex items-center"
                htmlFor="ExpandedInput"
              >
                <span className="ExpandableZone__Button__text__off">
                  Replier
                  <Icon name="chevron" orientation="up" primaryColor={color} />
                </span>
                <span className="ExpandableZone__Button__text__on">
                  Lire plus
                  <Icon name="chevron" orientation="down" primaryColor={color} />
                </span>
              </label>
            </div>
          </div>
        )}
      </div>
      <style jsx>
        {`
          .ExpandableZone__Content {
            max-height: ${nbChar > maxChar ? height : '100%'};
          }

          @media ${mixins.mediaQuery.tablet} {
            .ExpandableZone__Content {
              max-height: ${disabledDesktop ? '100%' : nbChar > maxChar ? height : '100%'};
            }

            .ExpandableZone__Expands {
              display: ${disabledDesktop ? 'none' : 'inline-block'};
            }
          }
        `}
      </style>

      <style jsx>
        {`
          .ExpandableZone {
            width: 100%;
            position: relative;
          }

          .ExpandableZone__Content {
            font-family: ${theme.cssVars.overpass};
            width: 100%;
            overflow: hidden;
          }

          .ExpandableZone__Expands {
            bottom: 0;
            width: 100%;
            z-index: 1;
            height: 40px;
            position: relative;
          }

          .ExpandableZone__Button {
            bottom: 0;
            width: 100%;
            height: 40px;
            position: absolute;

            font-family: ${theme.cssVars.overpass};
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;

            background-color: ${backgroundColor};
            color: ${color};
          }

          .ExpandableZone__Blur {
            position: absolute;
            background: linear-gradient(180deg, ${backgroundColor} 0%, transparent 100%);
            transform: rotate(-180deg);
            height: 50px;
            width: 100%;
            top: -50px;
            display: block;
          }

          .ExpandableZone__Button__Label {
            margin: auto;
            cursor: pointer;
          }

          #ExpandedInput:checked ~ .ExpandableZone__Content,
          .ExpandableZone__isAmp .ExpandableZone__Content {
            height: auto;
            max-height: initial;
          }

          #ExpandedInput,
          .ExpandableZone__isAmp .ExpandableZone__Button,
          #ExpandedInput:checked ~ .ExpandableZone__Expands > .ExpandableZone__Blur,
          .ExpandableZone__Button__text__off,
          #ExpandedInput:checked ~ .ExpandableZone__Expands .ExpandableZone__Button__text__on {
            display: none;
          }

          .ExpandableZone__Button__text__on,
          #ExpandedInput:checked ~ .ExpandableZone__Expands .ExpandableZone__Button__text__off {
            display: flex;
            align-items: center;
          }
        `}
      </style>
    </>
  )
}
